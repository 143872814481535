import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import About from '../views/About.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
    // component: () => import('../views/About.vue')
  },
  {
    path: '/terms-and-conditions',
    name: 'Security',
    component: () => import('../views/Security.vue')
  },
  {
    path: '/privacy-policy',
    name: 'Gdpr',
    component: () => import('../views/Gdpr.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    console.log(to, from, savedPosition)
    return {x: 0, y: 0};
  }
})

export default router
