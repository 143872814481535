<template>
<div>
  <b-navbar class="sticky-top" toggleable="lg" type="dark" variant="danger">

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="mx-auto my-auto">
        <b-nav-item class="my-auto">
            <router-link to="/">
                {{ $t('navbar.sluzby') }}
            </router-link>
        </b-nav-item>
        <b-nav-item class="my-auto">
            <router-link to="/about">
                {{ $t('navbar.zjistit_vic') }}
            </router-link>
        </b-nav-item>
        <b-navbar-brand class="logo" href="#">
            <router-link to="/">
                <b-img width="115" :src="require('../assets/logo.png')"></b-img>
            </router-link>
        </b-navbar-brand>
        <b-nav-item class="my-auto" disabled>
            <router-link to="/">
                {{ $t('navbar.premium') }}
            </router-link>
        </b-nav-item>
        
        <b-nav-item-dropdown toggle-class="text-dark" class="lang my-auto" :text="$t('navbar.jazyk')">
            <b-dropdown-item v-for="locale in locales" :key="locale" @click="switchLocale(locale)">
                <b-img width="20" :src="require(`../assets/${locale}.png`)" fluid></b-img>
                {{ locale }}
            </b-dropdown-item>
        </b-nav-item-dropdown>

      </b-navbar-nav>
    </b-collapse>

  </b-navbar>
</div>
</template>

<script>
    export default {
        name: "Navbar",
        data() {
            return {
                windowHeight: window.innerHeight,
                locales: ['cz', 'en', 'svk', 'de'],
            }
        },
        methods: {
            switchLocale(locale){
                this.$i18n.locale = locale;
            }
        }
    }
</script>

<style scoped>
 a{
     font-size: 1.35rem;
     color: black !important;
     text-decoration: none;
     font-weight: bold;
 }

 a:hover{
     color: black !important;
     font-weight: normal;
 }

 .nav-link{
    margin-left: 115px;
    margin-right: 120px;
 }

.lang{
    margin-left: 115px;
    margin-right: 120px;
 }

.lang{
     font-size: 1.35rem;
     color: black !important;
     text-decoration: none;
     font-weight: bold;
 }
 nav{
     background: rgb(0, 0, 0, 0.3) !important;
 }
.navbar .navbar-nav .nav-link a{
      color:black !important;
}

@media only screen and (max-width: 1200px) {
  .nav-link {
    text-align: center;
  }
  .lang{
      text-align: center;
  }
  .navbar-toggler{
      margin: 45px;
  }
  .logo{
      text-align: center;
  }
}
</style>