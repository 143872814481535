<template>
    <div class="footer">
        <b-row></b-row>
        <hr v-if="about" class="mt-5">

        <b-row class="mt-5 mb-3">
            <b-col>
                <h2 class="main-text download">{{ $t('footer.stahnout_aplikaci') }}</h2>
            </b-col>
        </b-row>
        <b-row cols="1" cols-lg="6" cols-md="1" cols-sm="1">
            <b-col class="my-auto banner">
                <a target="_blank" href="https://apps.apple.com/cz/app/lives/id1568099053?l=cs">
                    <b-img class="banner apple_play" width="240" :src="require('../assets/appstore.png')"></b-img>
                </a>
            </b-col>
            <b-col offset-lg="1" class="my-auto banner">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.dactylgroup.android.lifeapp">
                    <b-img class="banner google_play" width="300" :src="require('../assets/banner_google_play.png')"></b-img>
                </a>
            </b-col>
        </b-row>

        <b-row><b-col></b-col></b-row>
        <hr class="mt-5 mb-3">

        <b-row cols="1" cols-md="1" cols-lg="3" class="mt-5 text-center">
            <b-col>
                <b-row>
                    <b-col class="mb-2">
                        <h2 class="main-text">{{ $t('footer.bezpeci') }}</h2>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mb-1">
                        <router-link to="/terms-and-conditions">{{ $t('footer.smluvni_podminky') }}</router-link>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mb-1">
                        <router-link to="/privacy-policy">{{ $t('footer.gdpr') }}</router-link>
                    </b-col>
                </b-row>
            </b-col>
            <b-col>
                <b-row>
                    <b-col class="mb-2">
                        <h2 class="main-text margin-sm">{{ $t('footer.socialni_site') }}</h2>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <a class="link" href="https://www.instagram.com/lives.ap/" target="_blank">
                            <b-img class="banner" width="35" :src="require('../assets/instagram_icon.png')" fluid></b-img>
                        </a>
                        <a class="link" href="https://www.youtube.com/channel/UCv16D_BhjubxlSTnVOgiXRA" target="_blank">
                            <b-img class="banner" width="40" :src="require('../assets/youtube_icon.png')" fluid></b-img>
                        </a>
                        <a class="link" href="https://www.facebook.com/Lives-105589292057599/" target="_blank">
                            <b-img class="banner" width="35" :src="require('../assets/facebook_icon.png')" fluid></b-img>
                        </a>
                    </b-col>
                </b-row>
            </b-col>
            <b-col>
                <b-row>
                    <b-col>
                        <h2 class="main-text margin-sm">{{ $t('footer.kontakt') }}</h2>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        Dotazy, připomínky a podpora: <b><a href="mailto:Livesapcommunity@gmail.com">livesapcommunity@gmail.com</a></b>
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col>
                        Marketing, propagace a spolupráce: <b><a href="mailto:Livespromotion@gmail.com">livespromotion@gmail.com</a></b>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row class="mt-5"><b-col></b-col></b-row>
    </div>
</template>

<script>
export default{
    name: "Footer",
    computed: {
        about(){
            if(this.$route.name === "About")
                return false
            return true
        }
    }
}
</script>

<style scoped>
    a{
        color: black;
        text-decoration: none;
    }
    .footer{
        margin-top: 25px;
    }
    .main-text{
        font-size: 2.3rem !important;
        font-weight: bold;
    }
    .banner:hover{
        cursor: pointer;
    }
    .link{
        margin-left: 15px;
        margin-right: 15px;
    }
    @media only screen and (max-width: 767px) {
        .margin-sm{
            margin-top: 45px;
        }
        .download{
            text-align: center;
        }
        .banner{
            text-align: center;
        }
        .google_play{
            width: 240px;
        }
        .apple_play{
            width: 208px;
        }
    }
</style>
