<template>
    <div class="sticky-top navbar">
        <b-container>
            <b-row class="mt-2 mb-2">
                <b-col>
                    <router-link to="/">
                        <b-img width="100" :src="require('../assets/logo_colored.jpg')" fluid></b-img>
                    </router-link>
                </b-col>
                <b-col>
                    <b-img class="lang" @click="switchLocale('cz')" width="40" :src="require('../assets/cz.png')" fluid></b-img>
                    <b-img class="lang" @click="switchLocale('en')" width="40" :src="require('../assets/en.png')" fluid></b-img>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default{
    name: "BrandNavbar",
    methods: {
        switchLocale(locale){
            this.$i18n.locale = locale;
        }
    }
}
</script>

<style scoped>
    .navbar{
        background-color: white;
        border-bottom: 1px grey solid;
    }
    .lang{
        margin-left: 15px;
    }
    .lang:hover{
        cursor: pointer;
    }
</style>
