<template>
  <div>
    <div id="app" :class="{'bg': isNavbar, 'bg': home, 'bg2': !about}">
      <div class="xxx">
        <navbar v-if="isNavbar" />
      </div>
      <Brand-Navbar v-if="!isNavbar" />
      <router-view/>
      <div>
        <b-container>
          <Footer v-if="isNavbar" />
        </b-container>
      </div>
  </div>  

</div>
</template>

<script>
import Footer from './components/Footer.vue'
import Navbar from './components/Navbar.vue'
import BrandNavbar from './components/BrandNavbar.vue'

export default {
  name: "App",
  components: {
    Footer,
    Navbar,
    BrandNavbar
  },
  computed: {
    isNavbar(){
      if(this.$route.name === 'Gdpr' || this.$route.name === 'Security')
        return false    
      return true
    },
    home(){
      if(this.$route.name !== "Home")
        return false
      return true
    },
    about(){
      if(this.$route.name === "About")
        return false
      return true
    }
  },
  mounted(){
    document.title = "Lives"
  }
}
</script>


<style scoped>
  #app {
    /* font-family: 'Open Sans', sans-serif; */
    font-family: 'Roboto Condensed', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .bg{
    background: url('~@/assets/liquid_cheese.svg') no-repeat;
    height: 100%;
    background-size: cover;
  }
  .bg2{
    background: url('~@/assets/liquid_shadow_rotate.svg') no-repeat;
    height: 100%;
    background-size: cover;
  }
</style>
