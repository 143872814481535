<template>
    <b-container>
    <b-row class="mb-5 mt-5 text-center">
      <b-col>
        <h1 class="main-headline">{{ $t('main.hlavni_titulek') }}</h1>
      </b-col>
    </b-row>
    <b-row cols="1" cols-lg="2" cols-md="2" cols-sm="1" class="mb-5 mt-5">
      <b-col>
        <b-img loading="lazy" :src="require('../assets/welcome.jpg')" alt="Responsive image" fluid></b-img>
      </b-col>
      <b-col class="main-text about-text text-left mb-4">
        <b-row>
          <b-col>
            {{ $t('main.podtitulek') }}
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col offset-lg="2">
            <router-link to="/about">
              <b-button size="md" variant="outline-dark" class="primary main_button mt-5">
                {{ $t('main.zjistit_vic') }}
              </b-button>
            </router-link>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {
  name: 'Home',
}
</script>

<style scoped>
  .main-headline{
    font-weight: bold;
    font-size: 2.8rem !important;
  }
  .main-text{
    font-size: 2rem !important;
  }
  .main_button{
    font-size: 2rem;
  }
  .btn{
    min-width: 330px;
    border-radius: 25px;
  }

  @media only screen and (max-width: 767px) {
    .main-headline{
      font-size: 2.5rem !important;
    }
    .main-text{
      margin-top: 35px;
      text-align: center;
    }
    .btn{
      min-width: 250px;
    }
  }
</style>
